import React from "react"
import P from "../../core/P"
import H2 from "../../core/H2"

export const NewTimeLine = ({ TimeLineSlider }) => {
    const { timeline } = TimeLineSlider
    const SectionContent = e => (
        <div className="" key={Math.random()}>
            <div className="w-full">
                <H2
                    title={e.title}
                    className='text-shark-500 mt-2 '
                />
            </div>
            <P title={e.description} className="text-shark-400" />
        </div>
    )
    const SectionImage = e => (
        <div className="lg:w-11/12 w-full" key={Math.random()}>
            <img
                key={Math.random()}
                src={e?.images[0]?.mediaItemUrl}
                width={e?.images[0]?.mediaDetails?.width}
                height={e?.images[0]?.mediaDetails?.height}
                alt={e?.images[0]?.altText ? e?.images[0]?.altText : "Time Line Photos"}
                // title={e?.images[0]?.altText ? e?.images[0]?.altText : "Time Line Photos"}
            />
        </div>
    )

    return (
        <div className="bg-white py-12 relative">
            <div className="max-w-4xl mx-auto lg:px-8 md:px-4 px-6 lg:max-w-screen-2xl ">
                <div
                    className="max-w-screen-xl mx-auto py-12 sm:py-16 px-4 sm:px-6 lg:px-8"
                    data-aos="fade-up"
                >
                    <H2 title={`Our Journey`} className="text-center" />
                </div>
                <div className="lg:hidden block">
                    <>
                        {timeline.length > 0 &&
                            timeline.map((e) => (
                                <>
                                    <div
                                        className="lg:grid lg:grid-cols-2 lg:gap-10 z-0 top-0 md:mb-20 mb-12"
                                        data-aos="fade-up"
                                    >
                                        <div className="sm:text-center lg:text-left flex flex-col justify-center lg:order-1 md:order-none order-2">
                                            {SectionImage(e)}
                                        </div>
                                        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:text-left flex flex-col justify-center lg:order-1 md:order-none order-2">

                                            <h2
                                                dangerouslySetInnerHTML={{ __html: e.year }}
                                                className={`text-blue-300 font-serif font-bold lg:text-8xl tracking-normal text-4xl mt-10`}
                                            />
                                            <div className="flex h-full w-full justify-center items-center" >
                                                <hr
                                                    className="h-1 w-full bg-blue-300 my-5"
                                                />
                                            </div>
                                            {SectionContent(e)}
                                        </div>
                                    </div>
                                </>
                            ))}
                    </>
                </div>
                <div className="lg:block hidden">
                    <>
                        {timeline.length > 0 &&
                            timeline.map((e, index) => (
                                <>
                                    {e.year && index % 2 === 0 ? (
                                        <div className={`flex mt-16 w-full justify-start`}>
                                            <div
                                                className="w-2/5 flex flex-row"
                                                data-aos="fade-left"
                                            >
                                                <h2
                                                    dangerouslySetInnerHTML={{ __html: e.year }}
                                                    className={`text-blue-300 font-serif font-bold lg:text-8xl tracking-normal text-4xl mr-10`}
                                                />
                                                <div className="flex h-full w-full justify-center items-center" >
                                                    <hr
                                                        className="h-1 w-full bg-blue-300"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`flex mt-24 w-full justify-end`}>
                                            <div
                                                className="w-2/5 flex flex-row"
                                                data-aos="fade-right"
                                            >
                                                <div className="flex h-full w-full justify-center items-center" >
                                                    <hr
                                                        className="h-1 w-full bg-blue-300"
                                                    />
                                                </div>
                                                <h2
                                                    dangerouslySetInnerHTML={{ __html: e.year }}
                                                    className={`text-blue-300 font-serif font-bold lg:text-8xl tracking-normal text-4xl ml-10`}
                                                />
                                            </div>
                                        </div>
                                    )
                                    }
                                    {index % 2 === 0 ? (
                                        <div className={`lg:flex z-0 pb-10`}>
                                            <div
                                                className="sm:text-center lg:w-2/5 lg:text-left flex flex-col justify-center lg:order-1 md:order-none order-2"
                                                data-aos="fade-left"
                                            >
                                                {SectionContent(e)}
                                            </div>
                                            <div
                                                className="sm:text-center lg:w-3/5 lg:text-left flex md:justify-end justify-center lg:order-1 md:order-none order-2"
                                                data-aos="fade-right"
                                            >
                                                {SectionImage(e)}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={`lg:flex z-0 pb-10`}>
                                            <div
                                                className="sm:text-center lg:w-3/5 lg:text-left flex md:justify-start justify-center lg:order-1 md:order-none order-2"
                                                data-aos="fade-left"
                                            >
                                                {SectionImage(e)}
                                            </div>
                                            <div
                                                className="sm:text-center lg:w-2/5 lg:text-left flex flex-col justify-center lg:order-1 md:order-none order-2"
                                                data-aos="fade-right"
                                            >
                                                {SectionContent(e)}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                    </>
                </div>
            </div>
        </div>
    )
}

export default NewTimeLine
